import React, { Component } from 'react';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import generatePalette from 'lyft-coloralgorithm'

const dateTimeLabelFormats = {
  millisecond: '%A, %b %e, %H:%M:%S.%L',
  second: '%A, %b %e, %H:%M:%S',
  minute: '%I:%M %p',
  hour: '%l %p',
  day: '%b %e',
  week: '%b %e',
  month: '%b \'%y',
  year: '%Y'
}

const paletteParams = [
  {
    specs: {
      steps: 1,
      hue_start: 32, hue_end: 0, hue_curve: "easeInQuad",
      sat_start: 48, sat_end: 100, sat_curve: "easeOutQuad", sat_rate: 119,
      lum_start: 100, lum_end: 46, lum_curve: "easeOutQuad",
      modifier: 10
    }
  }, {
    specs: {
      steps: 1,
      hue_start: 62, hue_end: 94, hue_curve: "easeOutQuad",
      sat_start: 47, sat_end: 100, sat_curve: "easeInQuad", sat_rate: 124,
      lum_start: 100, lum_end: 46, lum_curve: "easeOutQuad",
      modifier: 10
    }
  }, {
    specs: {
      steps: 1,
      hue_start: 202, hue_end: 272, hue_curve: "easeInOutQuad",
      sat_start: 50, sat_end: 100, sat_curve: "easeOutQuad", sat_rate: 101,
      lum_start: 100, lum_end: 69, lum_curve: "easeInOutQuad",
      modifier: 10
    }
  }, {
    specs: {
      steps: 1,
      hue_start: 338, hue_end: 293, hue_curve: "easeInOutQuad",
      sat_start: 50, sat_end: 100, sat_curve: "easeOutQuad", sat_rate: 98,
      lum_start: 100, lum_end: 69, lum_curve: "easeInOutQuad",
      modifier: 10
    }
  }
]


class App extends Component {
  constructor (...args) {
    super(...args)

    this.state = { series: [] }
  }

  componentDidMount () {
    Highcharts.setOptions({
      lang: {
        thousandsSep: ','
      }
    })

    fetch('/api/data/store.json')
      .then(response => response.json())
      .then(data => {
        console.dir(data)

        let series = []
        let dateCounts = {}

        data = data.filter(({ date }) => {
          return Number(new Date(date)) > (Number(new Date()) - (1000 * 60 * 60 * 24 * 99))
        })

        data.forEach(({ date, payload }) => {
          const dateNum = Number(new Date(date))
          dateCounts[dateNum] = 0
          payload.forEach(({ count, version }) => {
            dateCounts[dateNum] += count
            let instances = series.filter(({ name }) => name === version).length
            if (instances === 0) {
              series.push({ name: version, data: [] })
            }
          })
        })

        console.dir(series)
        series = series.sort((a, b) => {
          const maxVersionLength = 20
          let aNumber = a.name.match(/^(?:\d(?:\.?))+/)
          let bNumber = b.name.match(/^(?:\d(?:\.?))+/)

          if (aNumber === null) {
            return 1
          }

          if (bNumber === null) {
            return -1
          }

          aNumber = aNumber[0]
          bNumber = bNumber[0]

          let aComponents = aNumber.split('.').map(str => Number(str))
          let bComponents = bNumber.split('.').map(str => Number(str))

          while (aComponents.length < maxVersionLength) {
            aComponents.push(0)
          }

          while (bComponents.length < maxVersionLength) {
            bComponents.push(0)
          }

          for (let i = 0; i < maxVersionLength; i++) {
            if (aComponents[i] > bComponents[i]) {
              return 1
            } else if (aComponents[i] < bComponents[i]) {
              return -1
            }
          }

          return -1
        })

        console.log(series.map(item => item.name).join('\n'))

        series = series.map(entry => {
          const { name } = entry
          const points = entry.data
          data.forEach(({ date, payload }) => {
            let match = payload.filter(({ version }) => name === version)[0]

            if (match) {
              const dateNum = Number(new Date(date))
              points.push(
                [dateNum, match.count / dateCounts[dateNum]]
              )
            }
          })

          return entry
        })

        console.dir(series)
        this.setState({ series })
      })
  }

  render () {
    const { series } = this.state
    const majorMinorColors = {}
    series.forEach((item, idx) => {
      if (item.name !== 'Unknown') {
        const major = item.name.match(/^\d+\.\d+/)[0]
        if (!majorMinorColors[major]) {
          majorMinorColors[major] = 1
        } else {
          majorMinorColors[major]++
        }
      }
    })

    const colors = Object.keys(majorMinorColors).map((major, idx) => {
      const params = { specs: { ...paletteParams[idx % paletteParams.length].specs } }
      const minorCount = majorMinorColors[major]
      params.specs.steps = minorCount

      return generatePalette(params)
    }).flat()

    const options = {
      colors: series.map((item, idx) => {
        if (item.name === 'Unknown') {
          return '#7f7f7f'
        } else {
          return colors[idx].hex
        }
      }),
      time: {
        timezone: 'America/Los_Angeles',
        useUTC: false
      },
      chart: {
        type: 'area'
      },
      title: {
        text: 'Versions'
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats,
        title: {
          text: 'Date'
        }
      },
      yAxis: {
        title: {
          text: 'Installations'
        },
        labels: {
          formatter: function () {
            console.dir(this)
            return `${this.value * 100}%`
          }
        },
        min: 0,
        max: 1
      },
      tooltip: {
        dateTimeLabelFormats,
        pointFormatter: function () {
          const percentage = Number((this.percentage).toFixed(1))
          return `<strong>${this.series.name}:</strong> ${(this.y * 100).toFixed(2)}%`
        },
        split: false
      },
      plotOptions: {
        area: {
          stacking: 'normal',
          lineColor: '#666666',
          lineWidth: 0.1,
          marker: {
            enabled: false
          }
        }
      },
      series
    }

    return (
      <HighchartsReact
        containerProps={{ className: 'chart' }}
        highcharts={Highcharts}
        options={options}
      />
    )
  }
}

export default App;
